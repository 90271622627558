
    import { defineComponent } from "vue";
    import vm from '@/main';
    import { courseModel} from "@/models/WorkspaceModel";
    import CategoryNodePath from "@/components/CategoryNodePath.vue";
    import WorkspaceCourseApi from "@/apis/WorkspaceCourseApi";

    export default defineComponent({
        name: "EditCouse",
        components: {
            CategoryNodePath,
        },
        data() {
            return {
                currentCourse: {
                    metadata: {name: "",type: "" },
                    imageUrl: '',
                    uuid: '',
                    courseFile:'',
                    categoryNodeId: '',
                    CategoryNodePath: ''
                } as courseModel,
                // name: '',
                courseFileLink:'',
                courseImageLink:'',
                action: '',
            };
        },
        props: {

        },
        computed: {
            actionOfCourse() {
                if(this.$router.currentRoute.path.includes('edit')) this.action = 'edit'
                else    this.action = 'create'
                this.currentCourse = this.$store.state.currentCourseWorkspace
                this.courseFileLink = this.currentCourse.courseFile;
                // if(this.action == 'edit'){
                //     this.currentCourse = this.$store.state.currentCourseWorkspace
                // }
            }
        },
        methods: {
            async saveEdit(){
                console.log("categoryNodeId: "+this.currentCourse.categoryNodeId)
                console.log("name: "+this.currentCourse.metadata.name)
                console.log("imageUrl: "+this.currentCourse.imageUrl)
                console.log("courseFile: "+this.currentCourse.courseFile)
                try{
                    this.$store.commit('updateLoading', true);
                    const createCourse = JSON.parse(JSON.stringify(await WorkspaceCourseApi.updateCourse(this.currentCourse)));
                    this.$router.push({ path: "/workspace/course" });
                }catch(err){
                    console.log(err)
                }finally{
                    this.$store.commit('updateLoading', false);
                }

            },
            getCategoryId(id: string){
                this.currentCourse.categoryNodeId = id;
            }
        },
        created(){
            this.actionOfCourse
        }
    });
