import api from '@/apis/BaseApi';
import vm from '@/main';
import { courseModel} from "@/models/WorkspaceModel";


export default class WorkspaceCourseApi {
    static async getCoursesByName(name:string, pageNumber:number): Promise<string> {
        const result = await api.get(`/workspace/courses/?name=${name}&pageNumber=${pageNumber}&pageSize=20`,);
        return result.data;
    }

    static async getCoursesById(id:string): Promise<string> {
        const result = await api.get(`/workspace/courses/${id}`,);
        return result.data;
    }

    static async deleteCourse(courseId:string): Promise<string> {
        const result = await api.delete(`/workspace/courses/${courseId}`,);
        return result.data;
    }

    static async updateCourse(course: courseModel): Promise<string> {
        let params: { [key: string]: any } = {};

        // 檢查每個欄位，若不為空則加入到參數物件中
        if (course.metadata.name!="") {
            params.name = course.metadata.name;
        }
        if (course.imageUrl!="") {
            params.imageUrl = course.imageUrl;
        }
        if (course.courseFile!="") {
            params.courseFile = course.courseFile;
        }
        if (course.categoryNodeId!="") {
            params.categoryNodeId = course.categoryNodeId;
        }
        // const result = await api.post(`/workspace/courses/${course.uuid}`, {name: course.metadata.name, imageUrl: course.imageUrl, courseFile: course.courseFile, categoryNodeId: course.categoryNodeId});
        const result = await api.post(`/workspace/courses/${course.uuid}`, params);
        return result.data;
    }
    static async createCourse(name:string, parentId:string): Promise<string> {
        const result = await api.post(`/workspace/courses`, {name: name, parentId: parentId});
        return result.data;
    }
    static async getFreeCourses(): Promise<string> {
        const result = await api.get(`/workspace/courses/freeCourses`,);
        return result.data;
    }
    static async updateFreeCourses(freeCourseId: string[]): Promise<string> {
        const result = await api.put(`/workspace/courses/freeCourses`, {courseIds:freeCourseId});
        return result.data;
    }
}